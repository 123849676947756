import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../styles/media';

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 3rem;

  @media ${media.mdUp} {
    width: 48%;
    margin-right: 2%;
    margin-top: 3rem;
  }

  @media ${media.xlUp} {
    width: 30%;
    margin-right: 3%;
    margin-top: 4rem;
  }
`;

const CardTitle = styled.h3`
  font-weight: 700;
`;

const CardLink = styled.a``;

class BlockCardLinkList extends Component {
  render() {
    const { items } = this.props.slice;

    return (
      <CardList className="container">
        {items.map((item, index) => (
          <Card key={index}>
            <GatsbyImage
              style={{
                borderRadius: '10px',
              }}
              image={item.picture.gatsbyImageData}
              alt={item.picture.alt ?? ''}
              className="drop-shadow"
            />
            <CardTitle className="mt-3">{item.text.text}</CardTitle>
            <CardLink
              href={`/${item.link?.document?.data?.slug}`}
              rel="noopener noreferrer"
              className="mt-4"
            >
              <div>
                <h3>{item.link_text.text}</h3>
              </div>
            </CardLink>
          </Card>
        ))}
      </CardList>
    );
  }
}

export default BlockCardLinkList;
