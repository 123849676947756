import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { gsap } from 'gsap'
import styled from 'styled-components'
// import { Helmet } from 'react-helmet'
import NavLinks from './NavLinks'
import NavLarge from './NavLarge'
import { media } from '../styles/media'
import logo from '../images/the-routing-company-mark.svg'
import arrow from '../images/arrow--newsletter.svg'
import close from '../images/close.svg'
import { navigate } from "gatsby"

const NavStyles = styled.nav`
  gap: 0;
  align-items: center;
  height: 74px;
  a {
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
  }
  .nav--hide {
    transition: all 0.3s ease-in;
  }
  .nav--cta {
    justify-content: flex-end;
    li:first-child {
      @media ${media.mdUp} {
        padding-right: 20px;
      }
      @media ${media.lgUp} {
        padding-right: 30px;
      }
    }
  }
  .nav--icon--menu {
    padding-left: 30px;
    @media ${media.mdUp} {
      padding-left: 20px;
    }
    @media ${media.lgUp} {
      padding-left: 30px;
    }
  }
  .icon--menu {
    width: 18px;
    display: block;
    fill: var(--color-primary);

    .line-1 {
      transform-origin: right center;
      transition: all 0.1s linear;
    }
    .line-2 {
      transform-origin: right bottom;
      transition: all 0.1s linear;
    }
    .line-3 {
      transform-origin: right top;
      transition: all 0.1s linear;
    }
    &:hover {
      .line-2 {
        transform: translate(-6px, 0);
        width: 14px;
      }
      .line-3 {
        transform: translate(-6px, 0);
        width: 14px;
      }
    }
  }
  .is-active {
    .icon--menu {
      .line-1 {
        width: 0;
      }
      .line-2 {
        transform: rotate(45deg) translate(-6px, 2px);
        width: 12px;
      }
      .line-3 {
        transform: rotate(-45deg) translate(-6px, -1px);
        width: 12px;
      }
    }
  }
  p {
    font-size: inherit;
  }
`

const NavDropdown = styled.div`
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  z-index: 19;
  width: 100%;
  height: 100vh;
  transform: translate(0, -150%);
  transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
  overflow-y: auto;
  overflow-x: hidden;

  @media ${media.lgUp} {
    height: auto;
  }
  
  a {
    text-decoration: none;
  }
  .container {
    height: 90%;
    @media ${media.mdUp} {
      height: 100%;
    }
    @media ${media.lgUp} {
      height: auto;
    }
    @media screen and ${media.mdUp} and (max-height: 759px) {
      height: auto;
    }
  }
  &.is-active {
    transform: translate(0, 0);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
  }
  .nav--contact {
    a {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
  .hide-height {
    display: none;
    @media screen and ${media.mdUp} and (min-height: 760px) {
      display: block;
    }
  }
  .height-margin {
    @media screen and ${media.mdUp} and (max-height: 759px) {
      margin-top: 0;
      padding-top: 0;
    }
  }



  &.is-active {
    .nav-drop-info {
      opacity: 1;
    }
  }

  &.is-sub {
    .nav-drop-info {
      transform: translateX(-100%);
      transition: 0.5s cubic-bezier(0.83, 0, 0.17, 1);
    }
    .nav--large {
      transform: translateX(-100%);
    }
  }
  .nav--large {
    transition: 0.5s cubic-bezier(0.83, 0, 0.17, 1);
  }
  .nav-drop-info {
    opacity: 0;
    transition: 0.5s cubic-bezier(0.83, 0, 0.17, 1);
  }
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navModalActive: false, // nav modal
      searchModalActive: false, // search modal
    }
  }

  componentDidMount() {
    let didNavScroll = false
    let lastScrollTop = 0
    const delta = 5
    const navHolder = document.querySelector('.nav--holder')
    const navHolderHeight = navHolder.clientHeight

    function checkNavScroll() {
      didNavScroll = true
    }

    function hasNavScrolled() {
      const st = document.documentElement.scrollTop
      const windowHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight

      if (Math.abs(lastScrollTop - st) <= delta) return

      if (st > lastScrollTop && st > navHolderHeight) {
        // Scroll Down
        navHolder.classList.remove('nav-down')
        navHolder.classList.add('nav-up')
        document.body.classList.remove('scroll-down')
        document.body.classList.add('scroll-up')
      } else {
        // Scroll Up
        if (st + windowHeight < documentHeight) {
          navHolder.classList.add('nav-down')
          navHolder.classList.remove('nav-up')
          document.body.classList.add('scroll-down')
          document.body.classList.remove('scroll-up')
        }
        if (st === 0) {
          navHolder.classList.remove('nav-down')
          document.body.classList.remove('scroll-down')
        }
      }

      lastScrollTop = st
    }

    setInterval(() => {
      if (didNavScroll) {
        hasNavScrolled()
        didNavScroll = false
      }
    }, 250)

    window.addEventListener('scroll', checkNavScroll)

    const fadeElements = gsap.timeline()
    fadeElements.to('.nav--holder', {
      opacity: 1,
      delay: 0.5,
    })

    const navElements = gsap.timeline()
    navElements
      .from('.nav--large li', {
        duration: 0.7,
        opacity: '0',
        delay: 0.4,
        y: '-7',
        stagger: {
          amount: '0.5',
        },
      })
      // .from(
      //   '.fade-1',
      //   {
      //     opacity: '0',
      //     y: '-7',
      //     duration: 0.3,
      //     delay: 0.4,
      //   },
      //   0
      // )

    this.animationNav = navElements
  }

  toggleModal = () => {
    const html = document.querySelector('html')
    const wrapper = document.querySelector('.wrapper')
    const modalBg = document.querySelector('.modal--bg')
    const htmlActive = document.querySelector('html.is-scroll-disabled-nav')
    if (htmlActive) {
      this.setState({ navModalActive: false })
      modalBg.classList.remove('is-active')
      html.classList.remove('is-scroll-disabled-nav')
      const scrollY = wrapper.style.top
      wrapper.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    } else {
      this.setState({ navModalActive: true })
      this.animationNav.restart().play()
      modalBg.classList.add('is-active')
      setTimeout(() => {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`
        html.classList.add('is-scroll-disabled-nav')
      }, 500)
    }
  }

  closeModal = () => {
    const html = document.querySelector('html')
    const wrapper = document.querySelector('.wrapper')
    const modalBg = document.querySelector('.modal--bg')
    const htmlActive = document.querySelector('html.is-scroll-disabled-nav')
    if (htmlActive) {
      this.setState({ navModalActive: false })
      modalBg.classList.remove('is-active')
      html.classList.remove('is-scroll-disabled-nav')
      const scrollY = wrapper.style.top
      wrapper.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }

  showSearch = () => {
    this.setState({
      searchModalActive: true
    })
  }
  closeSearch = () => {
    this.setState({
      searchModalActive: false
    })
  }
  submitSearch = () => {
    console.log('Search!')
  }

  render() {
    const { global } = this.props

    return (
      <>
        <div
          className={`nav--holder bg--main ${
            this.state.navModalActive ? 'is-active' : ''
          }`}
        >
          <NavStyles className="container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-2 md:col-span-1">
              <Link to="/" onClick={this.closeModal}>
                <img src={logo} alt="CloudKitchens" className="db" />
              </Link>
            </div>

            <div className="col-span-2 md:col-span-7 lg:col-span-11 flex items-center justify-end">
              <NavLinks nav={global.top_nav} />

              <ul className="nav--cta flex col-span-2 md:col-span-3">
                <li className="nav--hide hidden md:flex">
                  <Link
                    to={global.nav_button_url}
                    className="btn--rounded db"
                    onClick={this.closeModal}
                  >
                    {global.nav_button}
                  </Link>
                </li>
                <li className="searchbtn-wrapper">
                  <Link to="/search"><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero"/></svg></Link>
                  {/* <button onClick={this.showSearch}>
                    <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero"/></svg>
                  </button> */}
                </li>
                <li className="nav--icon--menu">
                  <a
                    onClick={this.toggleModal}
                    className={`${this.state.navModalActive ? 'is-active' : ''}`}
                  >
                    <svg version="1.1" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" x="0" y="0" className="icon--menu"
                    >
                      <rect x="0" y="4.1" className="line-1" width="12" height="1.6"
                      />
                      <rect x="4" y="8.4" className="line-2" width="8" height="1.6"
                      />
                      <rect x="4" y="0" className="line-3" width="8" height="1.6"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </NavStyles>
        </div>
        <div>
          <NavDropdown
            id="nav-drop"
            className={`pt-20 md:pt-24 md:pb-16 lg:pb-24 bg--main ${
              this.state.navModalActive ? 'is-active' : ''
            }`}
          >
            <div className="container grid grid-cols-1 md:grid-cols-8 lg:grid-cols-12 items-end flex flex-col">
              <div className="nav-drop-info hidden md:block order-2 lg:order-1 col-span-8 lg:col-span-7 md:mt-20 lg:mt-0 height-margin">
                <div className="w-8/12">
                  <p className="eyebrow color--grey-6 mb-3 hide-height">
                    {global.contact_heading}
                  </p>
                  <div className="w-27 small color--grey-5 hide-height">
                    <p className="w-27 small color--grey-5 hide-height">
                      {global.contact_content}
                    </p>
                  </div>
                  <div className="nav--contact flex justify-between mt-12">
                    <div>
                      <p className="eyebrow mb-3">Email</p>
                      <p className="small mb-0">
                        <a
                          href={`mailto:${global.contact_email}`}
                          className="menu--link color--grey-5"
                        >
                          {global.contact_email}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <NavLarge nav={global.dropdown_nav} closeModal={this.closeModal} />
              <div className="nav-drop-info pt-6 md:pt-20 md:pt-24 order-3 col-span-4 lg:col-span-7 lg:hidden height-margin">
                <div className="flex md:hidden nav--contact">
                  <div>
                    <p className="eyebrow mb-0 mt-2 md:mt-6">
                      Email
                      <br />
                      <a
                        href={`mailto:${global.contact_email}`}
                        className="menu--link color--grey-5"
                      >
                        {global.contact_email}
                      </a>
                    </p>
                  </div>
                </div>
                <p className="caption mt-10 md:mt-0 mb-0">
                  The Routing Company &copy;{new Date().getFullYear()}. All
                  Rights Reserved.
                </p>
              </div>
              <div className="nav-drop-info pt-24 hidden md:block lg:hidden order-4 col-span-4 lg:col-span-5">
                <ul className="flex justify-end">
                  <li className="caption ml-12">
                    <Link to="/terms-of-service">Terms of Service</Link>
                  </li>
                  <li className="caption ml-12">
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </NavDropdown>
        </div>
        <div className="modal--bg" onClick={this.toggleModal} />

        {/* Search modal */}
        <div className={(this.state.searchModalActive ? 'flex ' : 'hidden') + ' search-modal fixed top-0 left-0 right-0 h-screen bg-offwhite z-[200] flex-wrap items-center justify-center p-4' } aria-hidden={!this.state.searchModalActive}>
          <div className="max-w-lg w-full bg-offwhite flex items-center justify-center [ p-1 border border-black/10 rounded-full ]">
            <input className="grow bg-transparent block m-0 px-3 py-2 leading-none outline-none" placeholder="Search terms..." type="search" autoFocus={this.state.searchModalActive} />
            <button className="block m-0 leading-none"><img className="block m-0" src={arrow} alt="Search" width="36" height="36" onClick={this.submitSearch} /></button>
          </div>
          <button className="absolute top-0 right-0 p-4" onClick={this.closeSearch}>
            <img className="table" src={close} alt="Search" width="36" height="36" />
          </button>
        </div>

      </>
    )
  }
}

export default Header
