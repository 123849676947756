import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Cookies from 'js-cookie';
import { gsap } from 'gsap';
import validator from 'validator/es';
import React, { Component } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  &.dark {
    background-color: #ebeae4;
  }
`;

class BlockGatedContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
      email: '',
      firstName: '',
      lastName: '',
      company: '',
    };
  }

  componentDidMount() {
    const fadeUp = document.querySelectorAll('#block-home-01 .fade-up');

    fadeUp.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
        },
        duration: 0.5,
        opacity: '0',
        y: '20%',
      });
    });
  }

  required = ($type, $name) => {
    const holder = document.querySelector(`#${$type}`);
    holder.classList.add('error');
  };

  submitForm = (e) => {
    if (e) e.preventDefault();

    // eslint-disable-next-line react/destructuring-assignment
    if (!validator.isLength(this.state.firstName, { min: 1, max: undefined })) {
      this.required('first-name', 'First name');
      return false;
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (!validator.isLength(this.state.lastName, { min: 1, max: undefined })) {
      this.required('last-name', 'Last name');
      return false;
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (!validator.isEmail(this.state.email)) {
      this.required('email', 'Email');
      return false;
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (!validator.isLength(this.state.company, { min: 1, max: undefined })) {
      this.required('company', 'Company');
      return false;
    }

    const portalId = 5880939;
    const formId = '5234e5c7-8ee6-40db-9761-a173ff82898c';
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    this.setState({ loading: true });

    const urlString = window.location.href;
    const url = new URL(urlString);

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          // eslint-disable-next-line react/destructuring-assignment
          value: this.state.firstName.replace('&nbsp;', ''),
        },
        {
          name: 'lastname',
          // eslint-disable-next-line react/destructuring-assignment
          value: this.state.lastName.replace('&nbsp;', ''),
        },
        {
          name: 'email',
          // eslint-disable-next-line react/destructuring-assignment
          value: this.state.email,
        },
        {
          name: 'company',
          // eslint-disable-next-line react/destructuring-assignment
          value: this.state.company.replace('&nbsp;', ''),
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };

    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ submitted: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    const { primary } = this.props.slice;
    const headingText = primary?.page_heading;
    const headingColor = primary?.page_heading_color;
    const image = primary?.image1;
    const contentHeading = primary?.content_heading;
    const content = primary?.content;
    const cta = primary?.call_to_action;
    const gatedContent = primary?.gated_content;

    return (
      <Section
        // eslint-disable-next-line react/destructuring-assignment
        id={`block-home-${this.props.index}`}
        className={`[ BlockGatedContent ] relative  ${
          // eslint-disable-next-line react/destructuring-assignment
          this.props.index === 1 ? 'dark py-16 md:py-24' : 'pt-10 md:pt-24'
        }`}
      >
        <div className="container">
          {/* Heading */}
          {headingText && (
            <div className="grid grid-cols-4 md:grid-cols-12 md:mb-16">
              <div className="col-span-4 md:col-span-6">
                <h2
                  className={`h1 fade-up color--${
                    headingColor === 'blue' ? 'primary' : 'black'
                  } fade-up`}
                >
                  {headingText}
                </h2>
              </div>
            </div>
          )}

          {/* eyebrow */}
          {primary.eyebrow != null ? (
            <div
              className={`grid grid-cols-4 md:grid-cols-12 ${
                primary.size === 'Case Study' ? 'gap-6 lg:gap-12' : ''
              }`}
            >
              {primary.eyebrow && (
                <div className="col-span-4 md:col-span-2">
                  <span className="eyebrow-name fade-up">
                    {primary.eyebrow}
                  </span>
                </div>
              )}
            </div>
          ) : (
            ''
          )}

          <div className="grid grid-cols-12 gap-6 lg:gap-12">
            <div className="mt-10 col-span-10 sm:col-span-12 md:col-span-6">
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt ? image.alt : ''}
                className="radius-32 block mb-6 md:mb-0"
              />
            </div>

            <div className="col-span-10 sm:col-span-12 md:col-span-6">
              <h3
                className={`mb-6 opacity-50 fade-up ${
                  primary.size === 'Default' ? 'h2' : 'text-xl md:text-2xl'
                }`}
              >
                {contentHeading}
              </h3>
              <div className="html-content md:text-lg fade-up">
                <PrismicRichText field={content.richText} />

                {/* eslint-disable-next-line react/destructuring-assignment */}
                {!this.state.submitted ? (
                  <PrismicRichText field={cta.richText} />
                ) : (
                  ''
                )}

                {/* eslint-disable-next-line react/destructuring-assignment */}
                {this.state.submitted ? (
                  <PrismicRichText field={gatedContent.richText} />
                ) : (
                  ''
                )}

                {/* eslint-disable-next-line react/destructuring-assignment */}
                {!this.state.submitted ? (
                  <form onSubmit={this.submitForm}>
                    <div className="relative input-group mt-4 mb-4">
                      <input
                        type="text"
                        placeholder="First Name*"
                        id="first-name"
                        name="first-name"
                        onKeyUp={(e) => {
                          this.setState({ firstName: e.target.value });
                        }}
                      />
                      <label htmlFor="first-name">First Name*</label>
                    </div>

                    <div className="relative input-group mb-4">
                      <input
                        type="text"
                        placeholder="Last Name*"
                        id="last-name"
                        onKeyUp={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                      />
                      <label htmlFor="last-name">Last Name*</label>
                    </div>

                    <div className="relative input-group mb-4">
                      <input
                        type="text"
                        placeholder="Email*"
                        id="email"
                        onKeyUp={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                      <label htmlFor="email">Email*</label>
                    </div>

                    <div className="relative input-group mb-4">
                      <input
                        type="text"
                        placeholder="Company*"
                        id="company"
                        onKeyUp={(e) => {
                          this.setState({ company: e.target.value });
                        }}
                      />
                      <label htmlFor="company">Company*</label>
                    </div>

                    <button type="submit" className="btn--rounded bg--brown">
                      Send
                    </button>
                  </form>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export default BlockGatedContent;
