import React, { Component } from 'react'
import styled from 'styled-components'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import arrow from '../images/circle-arrow.svg'

class BlockHomeStories extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, global } = this.props
    console.log(!global ? '' : global)
    return (
      <section className="[ BlockHomeStories ] container relative pt-16 md:pt-20 pb-10">
        <div className="grid grid-cols-12 mb-10">
          <div
            className={`mb-6 md:mb-0 col-span-12 md:col-span-8 lg:col-span-${this.props.columns}`}
          >
            <h2 className={this.props.size}>{ !global ? '' : global.blog_heading }</h2>
          </div>
          <div className="col-span-12 md:col-span-4 lg:col-span-6 lg:col-start-7 flex md:justify-end items-start">
            <Link to={ !global ? '' : global.blog_button_url } className="btn--rounded block no-underline">
              { !global ? '' : global.blog_button }
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-x-12">
          { !data ? '' : data.edges.slice(0, 3).map((item, index) => (
            <div
              key={`homestory${index}`}
              className={`col-span-7 md:col-span-${
                index === 1 ? 3 : 2
              } mb-16 md:mb-0 ${
                index === 0 ? 'md:mt-10' : index === 1 ? 'md:mt-28' : ''
              }`}
            >
              <Link to={`/${item.node.data.slug}`} className="hover-zoom">
                {item.node.data.image?.gatsbyImageData
                  ? <div className="relative mb-8">
                      <div className="hover-zoom-img">
                        <GatsbyImage
                          image={item.node.data.image.gatsbyImageData}
                          alt={item.node.data.image.alt||''}
                          className="radius-32 block"
                        />
                      </div>
                      <img
                        src={arrow}
                        alt=""
                        className="absolute right-5 bottom-5"
                      />
                    </div>
                  : <div className="[noImage]"></div>
                }
                {item.node.data.date &&
                  <span className="mb-4 pill pill--gray">
                    {item.node.data.date}
                  </span>
                }
                <h3 className="text-lg font-medium mb-4">
                  {item.node.data.title.text}
                </h3>
                <p>{item.node.data.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </section>
    )
  }
}
export default BlockHomeStories
